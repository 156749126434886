/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { BASE_URL } from 'config';

const TravelAlerts = () => (
  <Layout page="travel-with-confidence">

    <Helmet
      title="Book with Confidence"
      meta={[
        {
          name: 'description', content: 'We understand that travel can feel a little uncertain right now, therefore we want to reassure you that we are doing everything we can to ensure your travel safety and provide maximum flexibility as the situation around novel coronavirus (COVID-19) continues to evolve.',
        },
        { name: 'og:title', content: 'Book with Confidence' },
        {
          name: 'og:description', content: 'We understand that travel can feel a little uncertain right now, therefore we want to reassure you that we are doing everything we can to ensure your travel safety and provide maximum flexibility as the situation around novel coronavirus (COVID-19) continues to evolve.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/travel-with-confidence/` }]}
    />
    <section className="l-section">
      <div className="l-container">
        <br />
        <br />
        <br />
        <br />
        <h1 className="l-contact-us__title c-heading c-heading--h1 u-text--center">Book your tour with confidence</h1>
        <br />
        <p>
          We’re stoked that the New Zealand borders are fully open to visitors from anywhere in the world.
        </p>
        <p>
          We can’t wait to have you travel with us. However we do wish to remind you to ensure you have the relevant entry requirements completed prior to your travel date.
        </p>
        <br />
        <h4 className="l-contact-us__title c-heading c-heading--h4">VISA INFORMATION</h4>
        <p>
          <ul>
            <li>NZeTA if you are from a Visa Waiver country: <a href="https://www.immigration.govt.nz/new-zealand-visas/apply-for-a-visa/tools-and-information/general-information/visa-waiver-countries" target="_blank">Visa waiver countries and territories</a></li>
            <li>Visa Waiver list can be found here: <a href="https://www.immigration.govt.nz/new-zealand-visas/apply-for-a-visa/tools-and-information/general-information/visa-waiver-countries" target="_blank">Visa waiver countries and territories</a></li>
            <li>
              If you are not from a Visa Waiver country please contact Immigration New Zealand directly to assess your entry
              requirements: <a href="https://www.immigration.govt.nz/" target="_blank">Welcome to Immigration New Zealand</a>
            </li>
          </ul>
        </p>
        <p>Please have a read of this <a href="http://files.hakatours.com/haka-tours-visa-entry.pdf" target="_blank">NZ visa information sheet</a> we have prepared.</p>
        <p>
          Travel insurance is mandatory for all our travellers and must be taken out at the time of booking.
          Your travel insurance must provide cover against personal accident, death, medical expenses and emergency
          repatriation with a recommended minimum coverage of US$200,000 for each of the categories of cover.
          More details can be found in our <a href="/booking-conditions/" target="_blank">booking terms and conditions</a>
        </p>
        <br />
        <h4 className="l-contact-us__title c-heading c-heading--h4">YOUR SAFETY IS OUR NUMBER ONE PRIORITY</h4>
        <p>
          For those of you booked onto an upcoming tour, you can be reassured that your health and safety is our number one priority and therefore we will be undertaking the following activities:
          <ul>
            <li>Our Haka buses are thoroughly sanitised before each tour.</li>
            <li>Our Haka Lodges are also undertaking additional measures to meet the WHO guidelines.</li>
            <li>We request our guests to follow the hygiene guidelines from WHO including the regular washing of hands.</li>
            <li>Our processes will be updated based on the latest guidelines.</li>
          </ul>
        </p>
        <p>
          Please refer to the <a href="https://www.who.int/" target="_blank">World Health Organisation</a> (WHO) or the <a href="https://uniteforrecovery.govt.nz/" target="_blank">New Zealand Covid-19 website</a> for any additional guidelines.
        </p>
        <br />
        <h4 className="l-contact-us__title c-heading c-heading--h4">RESERVE YOUR SPOT FOR JUST $99</h4>
        <p>
          Secure your place on a Haka Tour for just $99, and have the flexibility that you can change your tour or date,
          free of charge up until 60 days prior to your tour start date, subject to availability.
          If you would like to amend your booking please email <a href="mailto: info@hakatours.com">info@hakatours.com</a>.
        </p>
        <p><a href="/booking-conditions/" target="_blank">full booking terms and conditions here</a>.</p>
        <br />
        <h4 className="l-contact-us__title c-heading c-heading--h4">GET IN TOUCH</h4>
        <p>
          If you have any questions, please view our standard <a href="/booking-conditions/" target="_blank">terms and conditions</a>, or
          &nbsp;<a href="/about-us/faqs/" target="_blank">frequently asked questions (FAQs)</a>.
          Alternatively our reservations team would be happy to discuss your queries with you -&nbsp;
          <a href="mailto: info@hakatours.com">info@hakatours.com</a> or <a href="tel:+6495205593">+64 9 520 5593</a>.
        </p>
        {/* <br />
        <div className="alert-subscribe">
        <h2 className="l-contact-us__title c-heading c-heading--h2">STAY UPDATED</h2>
        <p>
          Want to be notified when New Zealand opens its borders again? Enter your email address below and we will let you know.
        </p>
        <br />
        <div className="c-newsletter-form__wrapper">
          <form acceptCharset="UTF-8" action="https://qj243.infusionsoft.com/app/form/process/e16f4a55e97c26f5a6ff62e9b07d2c17"
                className="infusion-form" id="inf_form_e16f4a55e97c26f5a6ff62e9b07d2c17" method="POST">
            <input name="inf_form_xid" type="hidden" value="e16f4a55e97c26f5a6ff62e9b07d2c17"/>
            <input name="inf_form_name" type="hidden" value="Web Form submitted"/>
            <input name="infusionsoft_version" type="hidden" value="1.70.0.83531"/>
            <div className="infusion-field">
              <input required className="infusion-field-input" id="inf_field_Email" name="inf_field_Email" placeholder="Enter your email address " type="text"/>
            </div>
            <div className="infusion-submit">
              <button className="infusion-recaptcha c-button c-button--border c-button--small" id="recaptcha_e16f4a55e97c26f5a6ff62e9b07d2c17" type="submit">Submit</button>
            </div>
          </form>
          <script type="text/javascript" src="https://qj243.infusionsoft.app/app/webTracking/getTrackingCode"></script>
          <script type="text/javascript"
                  src="https://qj243.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.83531"></script>
          <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async"
                  defer="defer"></script>
          <script type="text/javascript"
                  src="https://qj243.infusionsoft.com/app/timezone/timezoneInputJs?xid=e16f4a55e97c26f5a6ff62e9b07d2c17"></script>
        </div>
        </div> */}
      </div>
    </section>
  </Layout>
);

export default TravelAlerts;
